import { autoUpdate, offset, size, useFloating, useInteractions } from '@floating-ui/react';
import { twMergeClasses } from '../../lib';
import { CancelSymbol } from '@in2event/icons';
import type { UseComboboxStateChange } from 'downshift';
import { useCombobox } from 'downshift';
import type { ChangeEvent, ComponentPropsWithoutRef, ForwardedRef, ReactNode, Ref } from 'react';
import { forwardRef } from 'react';

import { Button } from '../button';
import { InputText } from '../input-text';
import { Loading } from '../loading';

interface AutocompleteProps<T> {
    ref?: Ref<HTMLInputElement>;
    options: T[];
    loading?: boolean;
    itemToString: (item: T | null) => string;
    selectedItem: T | null;
    onSelectedItemChange?: (changes: UseComboboxStateChange<T>) => void;
    inputValue?: string | undefined;
    onValueChange?: (value: string) => void;
    renderOption: (option: T) => ReactNode;
    onClear?: () => void;
    inputProps: Omit<ComponentPropsWithoutRef<typeof InputText>, 'ref'>;
}

function AutocompleteInner<T>(
    {
        options,
        loading = false,
        renderOption,
        itemToString,
        selectedItem = null,
        onSelectedItemChange,
        inputValue,
        onValueChange,
        onClear,
        inputProps,
    }: AutocompleteProps<T>,
    ref: ForwardedRef<HTMLInputElement>,
) {
    const { getInputProps, getMenuProps, getItemProps, isOpen, highlightedIndex } = useCombobox<T>({
        inputValue,
        items: options,
        itemToString,
        selectedItem,
        onSelectedItemChange,
    });

    const { refs, floatingStyles } = useFloating<HTMLDivElement>({
        whileElementsMounted: autoUpdate,
        open: isOpen,
        middleware: [
            // Debounce to fix annoying ResizeObserver loop limit exceeded error
            // https://github.com/floating-ui/floating-ui/issues/1740
            offset(4),
            size({
                apply: ({ rects, availableHeight, elements }) => {
                    requestAnimationFrame(() => {
                        Object.assign(elements.floating.style, {
                            width: `${rects.reference.width}px`,
                            maxHeight: `${availableHeight - 64}px`,
                        });
                    });
                },
            }),
        ],
    });

    const { getFloatingProps } = useInteractions();

    return (
        <>
            <div ref={refs.setReference} className="relative flex grow items-center justify-center">
                <InputText
                    {...getInputProps({
                        ref,
                        onChange: (e: ChangeEvent<HTMLInputElement>) => {
                            onValueChange && onValueChange(e.target.value);
                        },
                        autoComplete: 'off', // Disable Chrome's autocomplete suggestions
                        ...inputProps,
                    })}
                />
                {loading && <Loading className="absolute right-2 size-5" />}
                {selectedItem && (
                    <Button
                        type="button"
                        size="icon"
                        variant="ghost"
                        className="absolute right-2 text-neutral-750"
                        onClick={onClear}
                        tabIndex={-1}
                    >
                        <CancelSymbol className="size-5" />
                    </Button>
                )}
            </div>
            <div
                ref={refs.setFloating}
                {...getFloatingProps({
                    className: twMergeClasses(
                        'z-50 mt-base overflow-y-auto rounded-md bg-white shadow-elevation',
                    ),
                    style: {
                        ...floatingStyles,
                    },
                })}
            >
                <ul
                    {...getMenuProps({
                        className: twMergeClasses({
                            hidden: !(isOpen && options.length),
                        }),
                    })}
                >
                    {isOpen &&
                        options.map((option, index) => (
                            <li
                                key={index}
                                {...getItemProps({
                                    index,
                                    item: option,
                                })}
                                className={twMergeClasses(
                                    {
                                        'bg-[#F6F9FF]': highlightedIndex === index,
                                        'font-bold': selectedItem === option,
                                    },
                                    'flex flex-col px-3 py-2',
                                )}
                            >
                                {renderOption(option)}
                            </li>
                        ))}
                </ul>
            </div>
        </>
    );
}

// type assertion that restores the original function signature.
const Autocomplete = forwardRef(AutocompleteInner) as <T>(
    props: AutocompleteProps<T> & { ref?: ForwardedRef<HTMLInputElement> },
) => ReturnType<typeof AutocompleteInner>;

export { Autocomplete, type AutocompleteProps };
