'use client';

import { twMergeClasses } from '../../lib';
import type { LabelHTMLAttributes } from 'react';
import { forwardRef } from 'react';

export interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {}

const Label = forwardRef<HTMLLabelElement, LabelProps>(({ className, ...props }, ref) => (
    <label
        ref={ref}
        className={twMergeClasses(
            'text-xs font-medium text-neutral-60 peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
            className,
        )}
        {...props}
    />
));
Label.displayName = 'Label';

export { Label };
