import { twMergeClasses } from '../../lib';
import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';

const messagesVariants = {
    destructive: 'bg-destructive-200 text-destructive-800',
    warning: 'bg-warning-200 text-warning-700',
    primary: 'bg-primary-200 text-primary-700',
    affirmative: 'bg-affirmative-200 text-affirmative-700',
    standard: 'bg-neutral-200 text-neutral-700',
} as const;

export const Messages = forwardRef<
    HTMLDivElement,
    HTMLAttributes<HTMLDivElement> & {
        asChild?: boolean;
        variant?: keyof typeof messagesVariants;
    }
>(({ children, className, variant = 'standard', ...props }, forwardedRef) => {
    return (
        <div
            ref={forwardedRef}
            className={twMergeClasses(
                'rounded-lg px-4 py-3 text-sm font-semibold',
                messagesVariants[variant],
                className,
            )}
            {...props}
        >
            {children}
        </div>
    );
});

Messages.displayName = 'Messages';
