import { Slot, Slottable } from '@radix-ui/react-slot';
import { ButtonHTMLAttributes, forwardRef } from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { Loading } from '../loading';
import { twMergeClasses } from '../../lib';

const buttonVariants = cva(
    'rounded-md text-sm font-semibold transition-all focus:outline-none focus:ring-1 focus:ring-gray-200 disabled:cursor-not-allowed disabled:bg-neutral-6 disabled:text-neutral-60 disabled:opacity-60 flex shrink-0 flex-row items-center gap-1.5 text-center [&>svg]:fill-current',
    {
        variants: {
            variant: {
                standard:
                    'bg-neutral-1900 text-neutral-60 hover:bg-neutral-2000 hover:text-primary-600 active:bg-primary-300 active:text-primary-800',
                primary: 'bg-primary-600 text-white hover:bg-primary-700 active:bg-primary-800',
                destructive:
                    'bg-red-100 text-red-600 hover:bg-red-600 hover:text-white active:text-white active:bg-red-700',
                warning: 'bg-orange-600 text-white hover:bg-orange-700 active:bg-orange-800',
                affirming: 'bg-green-600 text-white hover:bg-green-700 active:bg-green-800',
                subtle: 'bg-transparent-600 text-neutral-60 hover:bg-neutral-1900 active:bg-neutral-20',
                ghost: 'bg-transparent border-none shadow-none disabled:hover:bg-transparent',
                link: 'bg-transparent text-primary-600 hover:underline hover:text-primary-800 active:text-primary-800 active:bg-neutral-6 disabled:no-underline',
                active: 'bg-neutral-90 text-white',
            },
            size: {
                icon: 'h-8 w-8 p-1.5 justify-center',
                'icon-sm': 'h-6 w-6 p-1 justify-center',
                sm: 'h-6 rounded-md py-1 px-3',
                md: 'h-8 py-1.5 px-3',
                lg: 'h-10 py-2.5 px-4 text-base leading-5',
                xl: 'h-12 py-2.5 px-4 text-base leading-5 w-full justify-center',
            },
            additions: {
                dropdown:
                    'aria-[expanded=true]:bg-neutral-90 aria-[expanded=true]:fill-white aria-[expanded=true]:text-white',
            },
        },
        defaultVariants: {
            variant: 'standard',
            size: 'md',
        },
    },
);

export interface ButtonProps
    extends ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    loading?: boolean;
    active?: boolean;
    asChild?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            variant,
            size,
            additions,
            loading = false,
            active = false,
            children,
            className,
            asChild = false,
            ...props
        },
        ref,
    ) => {
        const Comp = asChild ? Slot : 'button';

        return (
            <Comp
                ref={ref}
                className={twMergeClasses(buttonVariants({ variant, size, additions, className }), {
                    'pointer-events-none text-opacity-50': loading,
                    'bg-neutral-90 text-white': active,
                })}
                {...props}
            >
                {loading ? <Loading className="mr-1 size-5" /> : null}
                <Slottable>{children}</Slottable>
            </Comp>
        );
    },
);

Button.displayName = 'Button';

export { Button, buttonVariants };
