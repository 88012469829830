import { ErrorIcon } from '@in2event/icons';
import React from 'react';

import { Label } from '../label';
import { capitalizeFirstLetter, twMergeClasses } from '../../lib';

const variantClass = {
    compact: 'py-1.5 px-2 h-8',
    default: 'py-2.5 px-3 h-10',
};
type InputVariant = keyof typeof variantClass;

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label?: string;
    error?: string;
    success?: string;
    hideValidationLabel?: boolean;
    variant?: InputVariant;
}

export const InputText = React.forwardRef<HTMLInputElement, Props>(
    (
        {
            name,
            label,
            error,
            variant = 'default',
            hideValidationLabel = false,
            className,
            ...props
        },
        ref,
    ) => {
        return (
            <>
                {label && <Label htmlFor={name}>{capitalizeFirstLetter(label)}</Label>}
                <input
                    ref={ref}
                    id={name}
                    name={name}
                    className={twMergeClasses(
                        'flex w-full rounded-md border-2 border-neutral-10 bg-neutral-2100 text-sm font-medium text-neutral-250 placeholder:text-sm placeholder:font-medium placeholder:text-neutral-50',
                        'hover:bg-neutral-2100 focus:border-primary-600 focus:bg-white focus:outline-none focus:ring-0',
                        'disabled:cursor-not-allowed disabled:border-none disabled:bg-neutral-2100 disabled:text-neutral-1100',
                        variantClass[variant],
                        {
                            'border-destructive-600 focus:border-destructive-600': error,
                        },
                        className,
                    )}
                    {...props}
                />
                {error && !hideValidationLabel && (
                    <div className="pointer-events-none my-1">
                        <div className="relative flex items-start">
                            <div className="mr-1 shrink-0">
                                <ErrorIcon className="size-4 fill-destructive-600" />
                            </div>
                            <div className="grow break-words text-xs font-medium text-destructive-600">
                                {error}
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    },
);

InputText.displayName = 'InputText';
