import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

type ClassValue = ClassArray | ClassDictionary | string | number | null | boolean | undefined;
type ClassDictionary = Record<string, unknown>;
type ClassArray = ClassValue[];

const twMergeClasses = (...inputs: ClassValue[]): string => {
    const classes = clsx(...inputs);

    return twMerge(classes);
};

export { twMergeClasses };
